@font-face {
    font-family: "Lato-Bold";
    src: local("Lato-Bold"), url('https://fonts.googleapis.com/css2?family=Lato:wght@700;900&display=swap') format("truetype");
}

$white: #FFFFFF;
$white-2: #F8F8F8;

$gray-title: #3c3735;
$gray-text: #424242;

$gray-light: #abadb0;
$gray-lighter: #e3e3e4;

$purple: #341677;
$green: #B6C51F;
$pink: #FF4893;
$red-title: #D5003B;
$red: #D70036;

html,
body {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    margin: 0;
    padding: 0;
  font-family: 'Roboto', sans-serif;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    transition: all 500ms;
    overflow-x: hidden;
}

#app-header{
  z-index: 10;
}

.btn-grey{
  font: normal normal bold 16px/19px Roboto;
  color: #3C3735;
  border: 2px solid #3C3735;
  border-radius: 35px;
  padding: 1rem 2rem;
  bottom: 40px;
  left: 100px;
  box-shadow: none;
  @media (max-width: 992px) {
    display: flex;
    margin: 10px auto 10px;
  }
  &:hover{
    background-color: #3C3735;
    color: #fff;
  }
}

.donations{
  top: 315px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 279px;
  height: 80px;
  right: -200px;
  position: fixed;
  padding: 0 20px 0 20px;
  border-radius: 50px 0 0 50px;
  background-color: $red;
  color: #fff;
  font: normal normal bold 21px/25px Roboto;
  transition: 0.25s;
  @media (max-width: 992px) {
    display: none;
  }
  .sparks{
    animation: sparks 5s linear infinite;
  }
  @keyframes sparks {
    50% {
      opacity: 0;
    }
  }
  a{
    display: flex;
    flex-direction: column;
    align-items: end;
    text-decoration: none;
    color: #fff;
    span{
      font: italic normal bold 14px/17px Roboto;
    }
  }
  &:hover{
    transition: 0.25s;
    right: 0;
  }
}

.bg-gray{
  background-color: #f8f8f8;
}

.title-with-pill{
  display: flex;
  align-items: center;
  padding-left: 75px;
  position: relative;
  margin-bottom: 1rem;
  @media (max-width: 992px) {
    align-items: flex-start;
  }
  &.center{
    margin-right: auto;
    margin-left: auto;
    width: min-content;
  }
  .pill-title{
    width: 130px;
    height: 37px;
    background-color: #B6C51F;
    position: absolute;
    z-index: 0;
    border-radius: 35px;
    left: 0;
  }
  .texte{
    font: normal normal normal 20px/30px Poppins;
    color: #000000;
    letter-spacing: 0;
    text-transform: uppercase;
    z-index: 2;
    margin: 0;
    position: relative;
    left: -40px;
  }
}

.greenpills__span{
  position: relative;
  background-color: #B6C51F;
  width: 130px;
  height: 37px;
  h2, span{
    top: 4px;
    left: 75px;
    white-space: nowrap;
    position: absolute;
    @media (max-width: 766px) {
      white-space: normal;
      width: 80vw;
      left: 40px;
    }
  }
  h2{
    padding: 0 !important;
  }
}

.red-arrow-container{
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  margin: 0 15%;
  a{
    text-decoration: none;
    color: $gray-text;
    padding-top: 30px;
    display: flex;
    align-items: center;
    padding: 20px 20px;
    &:hover{
      color: $gray-title;
    }
    &:last-child{
      // padding-bottom: 30px;
    }
    img{
      margin-right: 20px;
    }
  }
}

.img-container{
  position: relative;
  z-index: 0;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 270px;

  .text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: -webkit-fill-available;
  }

  .etablissement__h1{
    font: normal normal normal 21px/25px Roboto;
    letter-spacing: 0;
    color: #FFFFFF;
    text-shadow: 0 0 15px #000000;
    text-transform: uppercase;
    width: -webkit-fill-available;
    text-align: center;
  }

  .breadcrumb__span{
    // font: normal normal normal 12px/14px Roboto;
    // letter-spacing: 0;
    // color: #9F9F9F;
    // width: -webkit-fill-available;
    // text-align: center;
    font: normal normal normal 12px/14px Roboto;
    letter-spacing: 0;
    color: #FFFFFF;
    text-shadow: 0 0 5px #000000;
    // text-transform: uppercase;
    width: -webkit-fill-available;
    text-align: center;
  }

  .ovale{
    position: absolute;
    top: 235px;
    left: -107.30093383789062px;
    z-index: 10;
  }

  .ovale2{
    position: absolute;
    left: 1054px;
    top: -115px;
    z-index: 10;
  }

  @media (max-width: 765px) {
    .ovale, .ovale2{
      display: none;
    }
  }
}

.pills{
  font: normal normal normal 12px/14px Roboto;
  color: #2E000D;
  display: flex;
  justify-content: flex-end;
  span{
    margin-left: 10px;
  }
}
.pills_cdi{
  border-radius: 12px;
  padding: 3px 12px;
  background-color: $green;
}
.pills_urgent{
  border-radius: 12px;
  padding: 3px 12px;
  background-color: $red;
  color: white;
}

.dropdown-item:hover, .dropdown-item:focus {
  background-color: transparent;
}
.content-page{
  padding-top: 120px;
  padding-bottom: 120px;
  padding-left: 300px;
  padding-right: 300px;
  @media (max-width: 1700px) {
    padding-left: 200px;
    padding-right: 200px;
  }
  @media (max-width: 1200px) {
    padding-left: 100px;
    padding-right: 100px;
  }
  @media (max-width: 766px) {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 20px;
  }
  @media (max-width: 400px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.card.card-black {
  background-color: #3c3735;
  border-radius: 5px;
  color: white;
  a {
    color: white;
    &:hover {
      color:darken(white,15);
    }
  }
}

.mat-dialog-container {
  padding: 0 !important;
  overflow-y: hidden !important;
}

.link {
  text-decoration: none;
  color: #B6C51F;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    color: #B6C51F;
  }
}

.modal-spontanee .mat-dialog-container {
  overflow-x: hidden !important;
}

.snackbar-success {
  background-color: $green;
  height: min-content;
  width: 30rem;
  display: flex;
  align-items: center;
  z-index: 999;
  &>div{
    width: 100%;
  }
  span {
    font-size: 1.2rem;
    font-weight: bold;
    color: white;
    &:first-child{
      opacity: 1;
    }
  }
}
.page-title {
  width: 100%;
  text-align: center;
  margin: 2rem 0 2rem 0;
}
.sanitaire__section{
  h2{
    font: normal normal medium 50px/61px Roboto !important;
    letter-spacing: 0;
    color: #3C3735;
    padding: 15px;
    @media (max-width: 992px) {
      font: normal normal bold 36px/40px Roboto;
      margin-top: 12px;
    }
  }
  .row-sanitaire{
    padding-top: 20px;
    @media (max-width: 766px) {
      padding-top: 20px;
    }
  }

  img {
    border-radius: 9px;
  }
}